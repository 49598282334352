<template>
  <svg
    id="Icon_ionic-ios-color-palette"
    :style="iconStyle"
    data-name="Icon ionic-ios-color-palette"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 24 23.998"
  >
    <path
      id="Icon_ionic-ios-color-palette-2"
      data-name="Icon ionic-ios-color-palette"
      d="M27.38,22.243a2.919,2.919,0,0,0-1.537-.562,2.288,2.288,0,0,1-1.412-.625,1.733,1.733,0,0,1,0-2.737l1.894-1.681a6.085,6.085,0,0,0,0-9.324A12.016,12.016,0,0,0,18.336,4.5,15.108,15.108,0,0,0,8.412,8.256a10.95,10.95,0,0,0,0,16.78A14.632,14.632,0,0,0,17.968,28.5h.106a14.065,14.065,0,0,0,9.3-3.275A2.11,2.11,0,0,0,27.38,22.243ZM8,14a2,2,0,1,1,2,2A2,2,0,0,1,8,14Zm2.5,7.874a2,2,0,1,1,2-2A2,2,0,0,1,10.5,21.874Zm4-10.062a2,2,0,1,1,2-2A2,2,0,0,1,14.5,11.812ZM19,25.5a3,3,0,1,1,3-3A3,3,0,0,1,19,25.5Zm1.5-13a2,2,0,1,1,2-2A2,2,0,0,1,20.5,12.5Z"
      transform="translate(-4.498 -4.5)"
      fill="#267c26"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '24',
    },
    width: {
      type: String,
      default: '24',
    },
    color: {
      type: String,
      default: 'primary-green',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
